// local storage prefix
const LOCAL_STORAGE_PREFIX = "imbglex_";

// App
export const APP = {
    NAME: process.env.REACT_APP_APP_NAME,
    SALT: process.env.REACT_APP_APP_SALT,
    VERSION: process.env.REACT_APP_APP_VERSION,
    FULL_NAME: process.env.REACT_APP_APP_FULL_NAME
};

// Company
export const COMPANY = {
    URL: process.env.REACT_APP_COMPANY_URL,
    NAME: process.env.REACT_APP_COMPANY_NAME,
    COPYRIGHT : process.env.REACT_APP_COMPANY_COPY_RIGHT
};

// localstorage key
export const LOCAL_STORAGE_KEY = {
    ACCESS_TOKEN: LOCAL_STORAGE_PREFIX + process.env.REACT_APP_LOCAL_STORAGE_ACCESS_TOKEN_KEY,
    REFRESH_TOKEN: LOCAL_STORAGE_PREFIX + process.env.REACT_APP_LOCAL_STORAGE_REFRESH_TOKEN_KEY
};

// Api url
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
