import React from "react";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import {DropdownItem, DropdownMenu} from "reactstrap";

const NavbarMenu = ({handleLogout, navMenuItems, activePath, history}) => {
    return (
        <DropdownMenu right>
            {navMenuItems.map((item, key) => (
                <DropdownItem
                    tag="a"
                    href="#"
                    key={key}
                    className={`${(item.path === activePath) ? "bg-secondary" : ""}`}
                    onClick={(e) => {
                        e.preventDefault();
                        history.push(item.path);
                    }}
                >
                    {item.icon}
                    <span className="align-middle">{item.title}</span>
                </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem tag="a" href="#" className="logout" onClick={handleLogout}>
                <Icon.Power size={15} className="mr-50" />
                <span className="align-middle">Deconnexion</span>
            </DropdownItem>
        </DropdownMenu>
    );
}

// Prop types to ensure destroyed props data type
NavbarMenu.propTypes = {
    history: PropTypes.object.isRequired,
    activePath: PropTypes.string.isRequired,
    handleLogout: PropTypes.func.isRequired,
    navMenuItems: PropTypes.array.isRequired,
};

export default React.memo(NavbarMenu);
