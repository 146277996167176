import React from "react";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import {Navbar, DropdownToggle, UncontrolledDropdown, NavItem, NavLink} from "reactstrap";

import NavbarMenu from "./NarbarMenu";
import useNavbarHook from "./useNavbarHook";
import DefaultAvatar from "../DefaultAvatar";
import NavbarNotifications from "./NarbarNotifications";

const AppNavbar = ({toggleSidebarVisibility, activePath, history}) => {
    // Component custom hooks
    const {userAvatar, userName, userRole, navMenuItems, handleLogout} = useNavbarHook();

    return (
        <>
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <Navbar className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow navbar-light floating-nav navbar">
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div className="navbar-collapse d-flex justify-content-between align-items-center" id="navbar-mobile">
                            <div className="bookmark-wrapper">
                                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                                    <ul className="navbar-nav d-xl-none">
                                        <NavItem className="mobile-menu mr-auto">
                                            <NavLink
                                                onClick={toggleSidebarVisibility}
                                                className="nav-menu-main menu-toggle hidden-xs is-active"
                                            >
                                                <Icon.Menu className="ficon" />
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>
                            </div>
                            <ul className="nav navbar-nav navbar-nav-user float-right">
                                <NavbarNotifications history={history} />
                                {/* User info area */}
                                <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                                        <div className="user-nav d-sm-flex d-none">
                                            <span className="user-name text-bold-600">{userName}</span>
                                            <span className="user-status">{userRole}</span>
                                        </div>
                                        <span data-tour="user">
                                            {(userAvatar)
                                                ? <img src={userAvatar} className="round" height="40" width="40" alt="..." />
                                                : <DefaultAvatar name={userName} />
                                            }
                                        </span>
                                    </DropdownToggle>
                                    <NavbarMenu
                                        history={history}
                                        activePath={activePath}
                                        handleLogout={handleLogout}
                                        navMenuItems={navMenuItems}
                                    />
                                </UncontrolledDropdown>
                            </ul>
                        </div>
                    </div>
                </div>
            </Navbar>
        </>
    );
};

// Prop types to ensure destroyed props data type
AppNavbar.propTypes = {
    history: PropTypes.object.isRequired,
    activePath: PropTypes.string.isRequired,
    toggleSidebarVisibility: PropTypes.func.isRequired,
};

export default React.memo(AppNavbar);
