import PropTypes from "prop-types";
import Hammer from "react-hammerjs";
import React, {useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import MenuBody from "./MenuBody";
import MenuHeader from "./MenuHeader";
import useMenuHook from "./useMenuHook";

const AppMenu = ({activePath, deviceWidth, sidebarCollapsed, sidebarHidden, toggleSidebarVisibility, handleSidebarCollapse}) => {
    // Local state
    const [menuShadow, setMenuShadow] = useState(false);
    const [sidebarHover, setSidebarHover] = useState(false);

    // Component custom hooks
    const {menuItems} = useMenuHook();

    // Data
    const layoutClass = `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-primary ${sidebarCollapsed ? "collapsed" : ""} ${(deviceWidth < 1200 && !sidebarHidden) ? "hide-sidebar" : ""}`;

    const handleSidebarHover = (flag) => {
        setSidebarHover(flag);
    };

    const scrollShadow = (container, up) => {
        if (container && up && container.scrollTop >= 100) setMenuShadow(true);
        else if (container && !up && container.scrollTop < 100) setMenuShadow(false);
    };

    return (
        <>
            <Hammer onSwipe={toggleSidebarVisibility} direction={"DIRECTION_RIGHT"}>
                <div className="menu-swipe-area d-xl-none d-block vh-100" />
            </Hammer>
            <div
                className={layoutClass}
                onMouseEnter={() => sidebarHover && handleSidebarCollapse(false)}
                onMouseLeave={() => sidebarHover && handleSidebarCollapse(true)}
            >
                <MenuHeader
                    menuShadow={menuShadow}
                    sidebarHover={sidebarHover}
                    handleSidebarHover={handleSidebarHover}
                    handleSidebarCollapse={handleSidebarCollapse}
                    toggleSidebarVisibility={toggleSidebarVisibility}
                />
                <PerfectScrollbar
                    options={{wheelPropagation: false}}
                    className="main-menu-content overflow-scroll"
                    onYReachStart={() => menuShadow && setMenuShadow(false)}
                    onScrollUp={(container) => scrollShadow(container, true)}
                    onScrollDown={(container) => scrollShadow(container, false)}
                >
                    <Hammer onSwipe={toggleSidebarVisibility} direction={"DIRECTION_RIGHT"}>
                        <ul className="navigation navigation-main">
                            <MenuBody
                                menuItems={menuItems}
                                activePath={activePath}
                                deviceWidth={deviceWidth}
                                toggleSidebarVisibility={toggleSidebarVisibility}
                            />
                        </ul>
                    </Hammer>
                </PerfectScrollbar>
            </div>
        </>
    );
};

// Prop types to ensure destroyed props data type
AppMenu.propTypes = {
    activePath: PropTypes.string.isRequired,
    deviceWidth: PropTypes.number.isRequired,
    sidebarHidden: PropTypes.bool.isRequired,
    sidebarCollapsed: PropTypes.bool.isRequired,
    handleSidebarCollapse: PropTypes.func.isRequired,
    toggleSidebarVisibility: PropTypes.func.isRequired
};

export default React.memo(AppMenu);
