import React, {lazy} from "react";
import * as Icon from "react-feather";

export const BASE = "/";

// Not found
export const NOT_FOUND = {
    NAME: "404",
    COMPONENT: lazy(() => import('../pages/NotFoundPage'))
};

// Login
export const LOGIN = {
    PATH: BASE,
    NAME: "Login",
    COMPONENT: lazy(() => import('../pages/login'))
};

// Dashboard
export const DASHBOARD = {
    NAME: "Tableau de bord",
    PATH: `${BASE}dashboard`,
    ICON: <Icon.PieChart size={20} className="mr-1" />,
    COMPONENT: lazy(() => import('../pages/dashboard'))
};

// Profile
export const PROFILE = {
    NAME: "Profil",
    PATH: `${BASE}profile`,
    ICON: <Icon.User size={15} className="mr-50" />,
    COMPONENT: lazy(() => import('../pages/profile'))
};

// Messages
export const MESSAGES = {
    NAME: "Messages",
    PATH: `${BASE}messages`,
    ICON: <Icon.MessageSquare size={15} className="mr-50" />,
    COMPONENT: lazy(() => import('../pages/messages'))
};

// Notifications
export const NOTIFICATIONS = {
    NAME: "Notifications",
    PATH: `${BASE}notifications`,
    ICON: <Icon.Bell size={20} />,
    COMPONENT: lazy(() => import('../pages/notifications'))
};
