import UIfx from "uifx";

import infoAudio from "../assets/audio/info.wav";
import dangerAudio from "../assets/audio/danger.wav";
import successAudio from "../assets/audio/success.mp3";
import warningAudio from "../assets/audio/warning.mp3";
import welcomeAudio from "../assets/audio/welcome.mp3";
import criticalAudio from "../assets/audio/critical.mp3";
import attentionAudio from "../assets/audio/attention.mp3";

import * as generalHelpers from "./generalHelpers";
import {NotificationManager} from "react-notifications";

// Play danger sound
export const playDangerSound = () => {
    try {
        const audio = new UIfx(dangerAudio, {volume: 1.0, throttleMs: 100});
        audio.play();
    } catch (e) {
        generalHelpers.log("Play danger sound error", e);
    }
};

// Play warning sound
export const playWarningSound = () => {
    try {
        const audio = new UIfx(warningAudio, {volume: 1.0, throttleMs: 100});
        audio.play();
    } catch (e) {
        generalHelpers.log("Play warning sound error", e);
    }
};

// Play success sound
export const playSuccessSound = () => {
    try {
        const audio = new UIfx(successAudio, {volume: 1.0, throttleMs: 100});
        audio.play();
    } catch (e) {
        generalHelpers.log("Play success sound error", e);
    }
};

// Play info sound
export const playInfoSound = () => {
    try {
        const audio = new UIfx(infoAudio, {volume: 1.0, throttleMs: 100});
        audio.play();
    } catch (e) {
        generalHelpers.log("Play info sound error", e);
    }
};

// Play attention sound
export const playAttentionSound = () => {
    try {
        const audio = new UIfx(attentionAudio, {volume: 1.0, throttleMs: 100});
        audio.play();
    } catch (e) {
        generalHelpers.log("Play attention sound error", e);
    }
};


// Play critical sound
export const playCriticalSound = () => {
    try {
        const audio = new UIfx(criticalAudio, {volume: 1.0, throttleMs: 100});
        audio.play();
    } catch (e) {
        generalHelpers.log("Play critical sound error", e);
    }
};

// Play welcome sound
export const playWelcomeSound = () => {
    try {
        const audio = new UIfx(welcomeAudio, {volume: 1.0, throttleMs: 100});
        audio.play();
    } catch (e) {
        generalHelpers.log("Play welcome sound error", e);
    }
};

// Apply info notification
export const applyInfoNotification = (message) => {
    playInfoSound();
    NotificationManager.info(message);
};

// Apply success notification
export const applySuccessNotification = (message) => {
    playSuccessSound();
    NotificationManager.success(message);
};

// Apply warning notification
export const applyWarningNotification = (message) => {
    playWarningSound();
    NotificationManager.warning(message);
};

// Apply danger notification
export const applyDangerNotification = (message) => {
    playDangerSound();
    NotificationManager.error(message);
};

// Apply welcome notification
export const applyWelcomeNotification = (message) => {
    playWelcomeSound();
    NotificationManager.success(message);
};