import {useSelector} from "react-redux";
import {useEffect, useMemo} from "react";

import {userHasPermission} from "../../helpers/generalHelpers";
import * as pageConstants from "../../constants/pageConstants";
import * as generalConstants from "../../constants/generalConstants";

const useMenuHook = () => {
    // Redux
    const user = useSelector(state => state.user);

    // Local effects
    useEffect(() => {
        checkDevice();
    }, []);

    // Create menu
    const menuItems = useMemo(() => {
        let items = [];
        const userPermissions = user.permissions;
        const basicPermission = userHasPermission(userPermissions, []);
        // Build menu
        basicPermission && items.push(simpleMenuItem(pageConstants.DASHBOARD));
        basicPermission && items.push(headerMenuItem("Immobilier"));
        return items;
    }, [user.permissions]);

    return {menuItems};
};

// External data
const {ITEM, HEADER} = generalConstants.MENU_ITEMS_TYPE;

// Check device
const checkDevice = () => {
    const prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
    const mq = (query) => window.matchMedia(query).matches;
    const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
    return mq(query);
};

// Simple menu item
const simpleMenuItem = (item) => {
    return {type: ITEM, icon: item.ICON, path: item.PATH, title: item.NAME};
};

// Group menu item
const headerMenuItem = (title) => {
    return {type: HEADER, title};
};

export default useMenuHook;
