const PREFIX = "merci de contacter l'adminstrateiur.";

// Controlled xhr request error
export const CONTROLLED_XHR_REQUEST_ERROR = {
    USER_DISABLED_ERROR: `Compte bloqué, ${PREFIX}`,
    PERMISSION_DENIED: `Permission réfusée, ${PREFIX}`,
    LOCAL_STORAGE_ERROR: `Erreur du stockage local, ${PREFIX}`,
    INVALID_TOKEN_ERROR: `Jetton de sécurité invalide, ${PREFIX}`,
    REQUEST_ERROR: `Cette requêtte n'a pas pue aboutir, ${PREFIX}`,
    UNAUTHORIZED_REQUEST_ERROR: `Requette non autorisée, ${PREFIX}`,
    FORM_DATA_ERROR: `Valeurs du formulaire incohérentes, ${PREFIX}`,
    USER_NOT_FOUND_BY_USERNAME: `Utilisateur non réconnu, ${PREFIX}`,
    DATABASE_ERROR: `Erreur du serveur de base de données, ${PREFIX}`,
    UNTOUCHABLE_ROLE: `Imposible de supprimé le rôle de base, ${PREFIX}`,
    USER_ALREADY_EXIST: `Cet utilisateur existe déjà dans le système, ${PREFIX}`,
    NO_PERMISSIONS_ERROR: `Pas de permission disponible dans la requêtte, ${PREFIX}`,
    USER_AUTH_ERROR: `Combinaision nom d'utilisateur/mot de passe incorrecte, ${PREFIX}`,
    USER_BROWSER_AND_TOKEN_MISMATCH: `Jetton de sécurité invalide sur ce navigateur, ${PREFIX}`
};

// Un controlled xhr request error
export const UNCONTROLLED_XHR_REQUEST_ERROR = {
    "Network Error": `Erreur du reseau, ${PREFIX}`,
    "timeout of 15000ms exceeded": `Delais t'attente expiré, ${PREFIX}`,
    "Request failed with status code 404": `Ressource non trouvable, ${PREFIX}`,
};
