import React  from 'react';
import PropTypes from "prop-types";

import "../assets/scss/components/app-loader.scss"

const Loader = ({small}) => {
    return (
        <div className={`${small ? "small-loading" : "loading"}`}>
            <div className="effect-1 effects" />
            <div className="effect-2 effects" />
            <div className="effect-3 effects" />
        </div>
    );
};

// Prop types to ensure destroyed props data type
Loader.propTypes = {
    small: PropTypes.bool
};

Loader.defaultProps = {
    small: false
};

export default React.memo(Loader);
