import * as actions from "./actions/cores";
import {requestInitValue, requestSucceededValue, requestFailedValue} from "../../helpers/requestHelpers";

// User partial store
const initialState = {
    role: '',
    name: '',
    email: '',
    phone: '',
    username: '',
    avatar: null,
    description: '',
    permissions: [],
    displayRole: '',
    isLoggedIn: undefined,
    requests: {
        logout: {failed: false, loading: false, succeeded: false, message: ''},
        refresh: {failed: false, loading: false, succeeded: false, message: ''},
        infoUpdate: {failed: false, loading: false, succeeded: false, message: ''},
        avatarUpdate: {failed: false, loading: false, succeeded: false, message: ''},
        avatarDelete: {failed: false, loading: false, succeeded: false, message: ''},
        authentication: {failed: false, loading: false, succeeded: false, message: ''},
    }
};

const reduce = (state = initialState, action) => {
    let nextState;
    switch (action.type) {

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Resolve event to set authentication init request store data
        case actions.STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_INIT:
            nextState = {
                ...state,
                requests: {...state.requests, authentication: requestInitValue()}
            };
            return nextState || state;
        // Resolve event to set authentication failed request store data
        case actions.STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_FAILED:
            nextState = {
                ...state,
                requests: {...state.requests, authentication: requestFailedValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set authentication succeeded request store data
        case actions.STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_SUCCEEDED:
            nextState = {
                ...state,
                isLoggedIn: true,
                name: action.payload.user?.name,
                role: action.payload.user?.role,
                phone: action.payload.user?.phone,
                email: action.payload.user?.email,
                avatar: action.payload.user?.avatar,
                username: action.payload.user?.username,
                permissions: action.payload.user?.permissions,
                description: action.payload.user?.description,
                requests: {...state.requests, authentication: requestSucceededValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set authentication reset request store data
        case actions.STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_RESET:
            nextState = {
                ...state,
                requests: {...state.requests, authentication: initialState.requests.authentication}
            };
            return nextState || state;
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Resolve event to set user data init request store data
        case actions.STORE_REFRESH_USER_DATA_REQUEST_INIT:
            nextState = {
                ...state,
                requests: {...state.requests, refresh: requestInitValue()}
            };
            return nextState || state;
        // Resolve event to set user data failed request store data
        case actions.STORE_REFRESH_USER_DATA_REQUEST_FAILED:
            nextState = {
                ...state,
                isLoggedIn: false,
                role: initialState.role,
                name: initialState.name,
                email: initialState.email,
                phone: initialState.phone,
                avatar: initialState.avatar,
                username: initialState.username,
                permissions: initialState.permissions,
                description: initialState.description,
                requests: {...state.requests, refresh: requestFailedValue()}
            };
            return nextState || state;
        // Resolve event to set user data succeeded request store data
        case actions.STORE_REFRESH_USER_DATA_REQUEST_SUCCEEDED:
            nextState = {
                ...state,
                isLoggedIn: true,
                name: action.payload.user?.name,
                role: action.payload.user?.role,
                email: action.payload.user?.email,
                phone: action.payload.user?.phone,
                avatar: action.payload.user?.avatar,
                username: action.payload.user?.username,
                permissions: action.payload.user?.permissions,
                description: action.payload.user?.description,
                requests: {...state.requests, refresh: requestSucceededValue(action.payload.message)}
            };
            return nextState || state;
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Resolve event to set user logout init request store data
        case actions.STORE_USER_LOGOUT_REQUEST_INIT:
            nextState = {
                ...state,
                requests: {...state.requests, logout: requestInitValue()}
            };
            return nextState || state;
        // Resolve event to set user logout failed request store data
        case actions.STORE_USER_LOGOUT_REQUEST_FAILED:
            nextState = {
                ...state,
                requests: {...state.requests, logout: requestFailedValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set user logout succeeded request store data
        case actions.STORE_USER_LOGOUT_REQUEST_SUCCEEDED:
            nextState = {
                ...state,
                isLoggedIn: false,
                name: initialState.name,
                role: initialState.role,
                phone: initialState.phone,
                email: initialState.email,
                avatar: initialState.avatar,
                username: initialState.username,
                permissions: initialState.permissions,
                description: initialState.description,
                requests: {...state.requests, logout: requestSucceededValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set user logout reset request store data
        case actions.STORE_USER_LOGOUT_REQUEST_RESET:
            nextState = {
                ...state,
                requests: {...state.requests, refresh: initialState.requests.logout}
            };
            return nextState || state;
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Resolve event to set user avatar update data init request store data
        case actions.STORE_USER_AVATAR_UPDATE_REQUEST_INIT:
            nextState = {
                ...state,
                requests: {...state.requests, avatarUpdate: requestInitValue()}
            };
            return nextState || state;
        // Resolve event to set user avatar update failed request store data
        case actions.STORE_USER_AVATAR_UPDATE_REQUEST_FAILED:
            nextState = {
                ...state,
                requests: {...state.requests, avatarUpdate: requestFailedValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set user avatar update succeeded request store data
        case actions.STORE_USER_AVATAR_UPDATE_REQUEST_SUCCEEDED:
            nextState = {
                ...state,
                avatar: action.payload?.avatar,
                requests: {...state.requests, avatarUpdate: requestSucceededValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set user avatar update  reset request store data
        case actions.STORE_USER_AVATAR_UPDATE_REQUEST_RESET:
            nextState = {
                ...state,
                requests: {...state.requests, avatarUpdate: initialState.requests.avatarUpdate}
            };
            return nextState || state;
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Resolve event to set user avatar delete data init request store data
        case actions.STORE_USER_AVATAR_DELETE_REQUEST_INIT:
            nextState = {
                ...state,
                requests: {...state.requests, avatarDelete: requestInitValue()}
            };
            return nextState || state;
        // Resolve event to set user avatar delete failed request store data
        case actions.STORE_USER_AVATAR_DELETE_REQUEST_FAILED:
            nextState = {
                ...state,
                requests: {...state.requests, avatarDelete: requestFailedValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set user avatar delete succeeded request store data
        case actions.STORE_USER_AVATAR_DELETE_REQUEST_SUCCEEDED:
            nextState = {
                ...state,
                avatar: initialState.avatar,
                requests: {...state.requests, avatarDelete: requestSucceededValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set user avatar delete reset request store data
        case actions.STORE_USER_AVATAR_DELETE_REQUEST_RESET:
            nextState = {
                ...state,
                requests: {...state.requests, avatarDelete: initialState.requests.avatarDelete}
            };
            return nextState || state;
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Resolve event to set user info update data init request store data
        case actions.STORE_USER_INFO_UPDATE_REQUEST_INIT:
            nextState = {
                ...state,
                requests: {...state.requests, infoUpdate: requestInitValue()}
            };
            return nextState || state;
        // Resolve event to set user info update failed request store data
        case actions.STORE_USER_INFO_UPDATE_REQUEST_FAILED:
            nextState = {
                ...state,
                requests: {...state.requests, infoUpdate: requestFailedValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set user info update succeeded request store data
        case actions.STORE_USER_INFO_UPDATE_REQUEST_SUCCEEDED:
            nextState = {
                ...state,
                name: action.payload?.name,
                phone: action.payload?.phone,
                email: action.payload?.email,
                description: action.payload?.description,
                requests: {...state.requests, infoUpdate: requestSucceededValue(action.payload.message)}
            };
            return nextState || state;
        // Resolve event to set user info update  reset request store data
        case actions.STORE_USER_INFO_UPDATE_REQUEST_RESET:
            nextState = {
                ...state,
                requests: {...state.requests, infoUpdate: initialState.requests.infoUpdate}
            };
            return nextState || state;
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // Unknown action
        default: return state;
    }
}

export default reduce;
