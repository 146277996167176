// Log
export const log = (message, data = null, highPriority = false) => {
    // Only in local environment
    if (process.env.NODE_ENV !== 'production' || highPriority) {
        console.log(Array(60).fill("#").join(""));
        console.log(`Message: ${message}`);
        data && console.log({data});
    }
};

// Search a needle in a string
export const needleSearch = (set, needle) => {
    if(set !== null && set !== '' && set !== undefined && set) {
        return set.toString().toLowerCase().indexOf(needle.toLowerCase()) !== -1;
    }
    return false;
};

// Check if user has permission
export const userHasPermission = (userPermissions, requiredPermission) => {
    let flag = true;
    requiredPermission.forEach((permission) => {
        if(!userPermissions.includes(permission)) {
            flag = false;
        }
    });
    return flag;
};

// Extract crouped image
export const extractCroppedImage = (imageSrc, pixelCrop) => {
    return new Promise((resolve, reject) => {
        base64ToImageObject(imageSrc)
            .then((image) => {
                // Data
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Ensure that context has been created
                (!ctx) && reject("unable to crete image context");

                // Set canvas width to final desired crop size
                canvas.width = pixelCrop.width
                canvas.height = pixelCrop.height

                // Build crouped image
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(
                    image,
                    pixelCrop.x,
                    pixelCrop.y,
                    pixelCrop.width,
                    pixelCrop.height,
                    0,
                    0,
                    pixelCrop.width,
                    pixelCrop.height
                );

                // Use this to save as base64 resolve(canvas.toDataURL('image/jpeg'));
                canvas.toBlob((file) => resolve({blob: file, src: URL.createObjectURL(file)}), 'image/jpeg');
            })
            .catch((error) => reject(error));
    });
};

// Read uploaded file
export const readFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.addEventListener('error', (error) => reject(error));
        reader.readAsDataURL(file);
    });
};

// Build image form base64
const base64ToImageObject = (base64Image) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = base64Image;
    });
};
