import ReactDOM from "react-dom";
import {Provider} from 'react-redux';
import React, {lazy, Suspense} from "react";

import {Layout} from "./layouts";
import {store} from "./redux/store";
import Loader from "./components/Loader";
import * as serviceWorker from "./serviceWorker";

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
    // Connect global store to components toward the router
    <Provider store={store}>
        <Suspense fallback={<Loader />}>
            <Layout><LazyApp /></Layout>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
