// Default input
export const DEFAULT_INPUT = {
    ARRAY: {data: [], errorMessage: '', isValid: true},
    STRING: {data: '', errorMessage: '', isValid: true}
};

// Menu items type
export const MENU_ITEMS_TYPE = {
    HEADER: 'header',
    ITEM: 'item',
};

// User permissions
export const PERMISSIONS = {
    BASIC: 'basic'
};
