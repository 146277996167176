export const EMIT_USER_LOGOUT = "EMIT_USER_LOGOUT";
export const EMIT_USER_INFO_UPDATE = "EMIT_USER_INFO_UPDATE";
export const EMIT_USER_AVATAR_DELETE = "EMIT_USER_AVATAR_DELETE";
export const EMIT_USER_AVATAR_UPDATE = "EMIT_USER_AVATAR_UPDATE";
export const EMIT_CHECK_USER_AUTHENTICATION = "EMIT_CHECK_USER_AUTHENTICATION";
export const EMIT_ATTEMPT_USER_AUTHENTICATION = "EMIT_ATTEMPT_USER_AUTHENTICATION";

// Emit check user auth
export const emitCheckUserAuthentication = () => ({
    type: EMIT_CHECK_USER_AUTHENTICATION
});

// Emit user logout
export const emitUserLogout = () => ({
    type: EMIT_USER_LOGOUT
});

// Emit user auth attempt
export const emitAttemptUserAuthentication = ({username, password}) => ({
    type: EMIT_ATTEMPT_USER_AUTHENTICATION,
    payload: {username, password}
});

// Emit user avatar update
export const emitUserAvatarUpdate = ({blob, src}) => ({
    type: EMIT_USER_AVATAR_UPDATE,
    payload: {blob, src}
});

// Emit user avatar delete
export const emitUserAvatarDelete = () => ({
    type: EMIT_USER_AVATAR_DELETE
});

// Emit user info update delete
export const emitUserInfoUpdate= ({name, email, phone, description}) => ({
    type: EMIT_USER_INFO_UPDATE,
    payload: {name, email, phone, description}
});

