import React  from 'react';
import PropTypes from "prop-types";

const DefaultAvatar = ({big, name}) => {
    return (
        <div className={`avatar bg-primary ${big ? "avatar-lg" : "avatar-sm"}`}>
          <span className="avatar-content">{name?.charAt(0)?.toUpperCase()}</span>
        </div>
    );
};

// Prop types to ensure destroyed props data type
DefaultAvatar.propTypes = {
    big: PropTypes.bool,
    name: PropTypes.string.isRequired
};

DefaultAvatar.defaultProps = {
    big: false
};

export default React.memo(DefaultAvatar);
