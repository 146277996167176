import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";

import Menu from "../components/menu";
import Footer from "../components/Footer";
import Navbar from "../components/navbar";

const AuthLayout = ({children, match, history}) => {
    // Local state
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [sidebarHidden, setSidebarHidden] = useState(false);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    // Data
    const activePath = match.path;
    const layoutClass = `wrapper vertical-layout theme-primary navbar-floating ${(sidebarCollapsed && deviceWidth >= 1200) ? "menu-collapsed" : ""}`;

    // Local effects
    useEffect(() => {
        const listener = () => {
            setDeviceWidth(window.innerWidth);
        };
        window && window.addEventListener("resize", listener, false);
        document && document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
        return () => window && window.removeEventListener("resize", listener);
        // eslint-disable-next-line
    }, []);

    const toggleSidebarVisibility = () => {
        setSidebarHidden(!sidebarHidden);
    };

    const handleSidebarCollapse = (flag) => {
        setSidebarCollapsed(flag);
    };

    return (
        <div className={layoutClass}>
            <Menu
                activePath={activePath}
                deviceWidth={deviceWidth}
                sidebarHidden={sidebarHidden}
                sidebarCollapsed={sidebarCollapsed}
                handleSidebarCollapse={handleSidebarCollapse}
                toggleSidebarVisibility={toggleSidebarVisibility}
            />
            <div className="app-content content">
                <Navbar
                    history={history}
                    activePath={activePath}
                    toggleSidebarVisibility={toggleSidebarVisibility}
                />
                <div className="content-wrapper">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    )
};

// Prop types to ensure destroyed props data type
AuthLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default React.memo(AuthLayout);
