import { all, takeEvery, put, fork, call, takeLatest } from 'redux-saga/effects'

import * as cores from "./actions/cores";
import * as middlewares from "./actions/middlewares";
import * as urlConstants from "../../constants/urlConstants";
import * as envConstants from "../../constants/envConstants";
import * as xhrRequestHelpers from "../../helpers/xhrRequestHelpers";
import * as localStorageHelpers from "../../helpers/localStorageHelpers";

// External data
const {xhrPostRequest, xhrDeleteRequest} = xhrRequestHelpers;
const {setLocaleStorageItem, removeAllLocaleStorageItems} = localStorageHelpers;

// Attempt user authentication from API
export function* emitAttemptUserAuthentication() {
    yield takeLatest(middlewares.EMIT_ATTEMPT_USER_AUTHENTICATION, function*({payload}) {
        // Fire event for request
        yield put(cores.storeAttemptUserAuthenticationRequestInit());
        // API call (Attempt user authentication)
        const {status, data, message} = yield call(xhrPostRequest, urlConstants.AUTH.LOGIN, payload);
        if(status) {
            // Set user data into local storage
            const {tokens, user} = data;
            yield call(setLocaleStorageItem, envConstants.LOCAL_STORAGE_KEY.ACCESS_TOKEN, tokens?.access);
            yield call(setLocaleStorageItem, envConstants.LOCAL_STORAGE_KEY.REFRESH_TOKEN, tokens?.refresh);
            // Fire event for request
            const message = `Bienvenue ${user.name || ''}`;
            yield put(cores.storeAttemptUserAuthenticationRequestSucceeded({message, user}));
        } else {
            yield put(cores.storeAttemptUserAuthenticationRequestFailed({message}));
        }
    });
}

// Check user authentication from data in local storage
export function* emitCheckUserAuthentication() {
    yield takeEvery(middlewares.EMIT_CHECK_USER_AUTHENTICATION, function*() {
        // Fire event for request
        yield put(cores.storeUserRefreshDataRequestInit());
        // API call (Fetch user data into backend)
        const {status, data} = yield call(xhrPostRequest, urlConstants.AUTH.REFRESH);
        if(status) {
            // Fire event for request
            yield put(cores.storeUserRefreshDataRequestSucceeded({user: data}));
        } else {
            yield put(cores.storeUserRefreshDataRequestFailed());
        }
    });
}

// Remove user data present into local storage while logout from API
export function* emitUserLogout() {
    yield takeLatest(middlewares.EMIT_USER_LOGOUT, function*() {
        // Fire event for request
        yield put(cores.storeUserLogoutRequestInit());
        // API call (Fetch user data into backend)
        const {status, message} = yield call(xhrPostRequest, urlConstants.AUTH.LOGOUT);
        if(status) {
            yield call(removeAllLocaleStorageItems);
            // Fire event for request
            const message = "Vous êtes déconnectés avec succès";
            yield put(cores.storeUserLogoutRequestSucceeded({message}));
        } else {
            yield put(cores.storeUserLogoutRequestFailed({message}));
        }
    });
}

// Attempt user avatar update from API
export function* emitUserAvatarUpdate() {
    yield takeLatest(middlewares.EMIT_USER_AVATAR_UPDATE, function*({payload}) {
        // Data
        const {blob, src} = payload;
        let data = new FormData();
        data.append('picture', blob);
        const config = {headers: {'content-type': 'multipart/form-data'}};
        // Fire event for request
        yield put(cores.storeUserAvatarUpdateRequestInit());
        // API call (Attempt user authentication)
        const {status, message} = yield call(xhrPostRequest, urlConstants.USER.AVATAR.UPDATE, data, config);
        if(status) {
            // Fire event for request
            const message = `Photo de profil mise à jour avec succès`;
            yield put(cores.storeUserAvatarUpdateRequestSucceeded({message, avatar: src}));
        } else {
            yield put(cores.storeUserAvatarUpdateRequestFailed({message}));
        }
    });
}

// Attempt user avatar update from API
export function* emitUserAvatarDelete() {
    yield takeLatest(middlewares.EMIT_USER_AVATAR_DELETE, function*() {
        // Fire event for request
        yield put(cores.storeUserAvatarDeleteRequestInit());
        // API call (Attempt user authentication)
        const {status, message} = yield call(xhrDeleteRequest, urlConstants.USER.AVATAR.DELETE);
        if(status) {
            // Fire event for request
            const message = `Photo de profil supprimée avec succès`;
            yield put(cores.storeUserAvatarDeleteRequestSucceeded({message}));
        } else {
            yield put(cores.storeUserAvatarDeleteRequestFailed({message}));
        }
    });
}

// Attempt user info update from API
export function* emitUserInfoUpdate() {
    yield takeLatest(middlewares.EMIT_USER_INFO_UPDATE, function*({payload}) {
        // Fire event for request
        yield put(cores.storeUserInfoUpdateRequestInit());
        // API call (Attempt user authentication)
        const {status, message} = yield call(xhrPostRequest, urlConstants.USER.INFO, payload);
        if(status) {
            // Fire event for request
            const message = `Informations generales mises à jour avec succès`;
            yield put(cores.storeUserInfoUpdateRequestSucceeded({
                message,
                name: payload.name,
                email: payload.email,
                phone: payload.phone,
                description: payload.description,
            }));
        } else {
            yield put(cores.storeUserInfoUpdateRequestFailed({message}));
        }
    });
}

export default function* saga() {
    yield all([
        fork(emitUserLogout),
        fork(emitUserInfoUpdate),
        fork(emitUserAvatarUpdate),
        fork(emitUserAvatarDelete),
        fork(emitCheckUserAuthentication),
        fork(emitAttemptUserAuthentication),
    ]);
}
