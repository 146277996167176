import CryptoJS from "crypto-js";

import * as generalHelpers from "./generalHelpers";
import * as envConstants from "../constants/envConstants";

// Get local storage item
export const getLocaleStorageItem = (key, secure = true) => {
    const data = localStorage.getItem(key);
    if (!data) {
        generalHelpers.log("Read local storage error");
        return data;
    }
    return decrypt(data, secure);
};

// Set local storage item
export const setLocaleStorageItem = (key, value, secure = true) => {
    const data = encrypt(value, secure);
    localStorage.setItem(key, data);
};

// Remove local storage item
export const removeLocaleStorageItem = (key) => {
    localStorage.removeItem(key);
};

// Remove all item from local storage
export const removeAllLocaleStorageItems = () => {
    localStorage.clear();
}

// Encrypt data
const encrypt = (data, secure) => {
    const StringData = JSON.stringify(data);
    return secure
        ? CryptoJS.AES.encrypt(StringData, envConstants.APP.SALT).toString()
        : StringData;
};

// Decrypt data
const decrypt = (data, secure) => {
    if(secure) {
        const bytes = CryptoJS.AES.decrypt(data, envConstants.APP.SALT);
        const StringData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(StringData);
    }
    return JSON.parse(data);
};