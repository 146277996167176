import * as envConstants from "./envConstants";

const API_URL = `${envConstants.API_BASE_URL}/api/web/v1`;

const AUTH_API_URL = `${API_URL}/auth`;
const USER_API_URL = `${API_URL}/user`;

// Auth
export const AUTH = {
    TOKEN: `${AUTH_API_URL}/token`,
    LOGIN: `${AUTH_API_URL}/login`,
    LOGOUT: `${AUTH_API_URL}/logout`,
    REFRESH: `${AUTH_API_URL}/refresh`,
};

// User
export const USER = {
    AVATAR: {
        UPDATE: `${USER_API_URL}/avatar-update`,
        DELETE: `${USER_API_URL}/avatar-delete`,
    },
    PASSWORD: `${USER_API_URL}/password-update`,
    INFO: `${USER_API_URL}/info-update`,
}
