import React from "react";
import PropTypes from "prop-types";

const GuestLayout = ({children}) => {
    return (
        <div className="full-layout wrapper bg-full-screen-image blank-page">
            <div className="app-content">
                <div className="content-wrapper">
                    <div className="content-body">
                        <div className="flexbox-container">
                            <main className="main w-100">
                                {children}
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Prop types to ensure destroyed props data type
GuestLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default React.memo(GuestLayout);
