import {useEffect, useMemo} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";

import * as soundHelpers from "../../helpers/soundHelpers";
import * as pageConstants from "../../constants/pageConstants";
import * as requestHelpers from "../../helpers/requestHelpers";
import * as generalHelpers from "../../helpers/generalHelpers";
import {emitUserLogout} from "../../redux/user/actions/middlewares";

const useNavbarHook = () => {
    // Redux
    const dispatch = useDispatch();
    const {user, userLogoutRequest} = useSelector(state => ({
        user: state.user,
        userLogoutRequest: state.user.requests.logout,
    }), shallowEqual);

    // Data
    const userName = user.name;
    const userRole = user.role;
    const userAvatar = user.avatar;

    // user logout
    const handleLogout = () => {
        dispatch(emitUserLogout());
    };

    // Local effect
    useEffect(() => {
        // Logout failed notification
        if(requestFailed(userLogoutRequest)) {
            applyDangerNotification(userLogoutRequest.message);
        }
    }, [userLogoutRequest]);

    // Create menu
    const navMenuItems = useMemo(() => {
        let items = [];
        const userPermissions = user.permissions;
        const basicPermission = userHasPermission(userPermissions, []);
        // Build menu
        basicPermission && items.push(navMenuItem(pageConstants.PROFILE));
        basicPermission && items.push(navMenuItem(pageConstants.MESSAGES));
        return items;
    }, [user.permissions]);

    return {userAvatar, userName, userRole, navMenuItems, handleLogout};
};

// External data
const {requestFailed} = requestHelpers;
const {userHasPermission} = generalHelpers;
const {applyDangerNotification} = soundHelpers;
const navMenuItem = (item) => {
    return {icon: item.ICON, path: item.PATH, title: item.NAME};
};

export default useNavbarHook;
