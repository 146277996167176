export const STORE_USER_LOGOUT_REQUEST_INIT = 'STORE_USER_LOGOUT_REQUEST_INIT';
export const STORE_USER_LOGOUT_REQUEST_RESET = 'STORE_USER_LOGOUT_REQUEST_RESET';
export const STORE_USER_LOGOUT_REQUEST_FAILED = 'STORE_USER_LOGOUT_REQUEST_FAILED';
export const STORE_USER_LOGOUT_REQUEST_SUCCEEDED = 'STORE_USER_LOGOUT_REQUEST_SUCCEEDED';

export const STORE_USER_INFO_UPDATE_REQUEST_INIT = 'STORE_USER_INFO_UPDATE_REQUEST_INIT';
export const STORE_USER_INFO_UPDATE_REQUEST_RESET = 'STORE_USER_INFO_UPDATE_REQUEST_RESET';
export const STORE_USER_INFO_UPDATE_REQUEST_FAILED = 'STORE_USER_INFO_UPDATE_REQUEST_FAILED';
export const STORE_USER_INFO_UPDATE_REQUEST_SUCCEEDED = 'STORE_USER_INFO_UPDATE_REQUEST_SUCCEEDED';

export const STORE_REFRESH_USER_DATA_REQUEST_INIT = 'STORE_REFRESH_USER_DATA_REQUEST_INIT';
export const STORE_REFRESH_USER_DATA_REQUEST_FAILED = 'STORE_REFRESH_USER_DATA_REQUEST_FAILED';
export const STORE_REFRESH_USER_DATA_REQUEST_SUCCEEDED = 'STORE_REFRESH_USER_DATA_REQUEST_SUCCEEDED';

export const STORE_USER_AVATAR_UPDATE_REQUEST_INIT = 'STORE_USER_AVATAR_UPDATE_REQUEST_INIT';
export const STORE_USER_AVATAR_UPDATE_REQUEST_RESET = 'STORE_USER_AVATAR_UPDATE_REQUEST_RESET';
export const STORE_USER_AVATAR_UPDATE_REQUEST_FAILED = 'STORE_USER_AVATAR_UPDATE_REQUEST_FAILED';
export const STORE_USER_AVATAR_UPDATE_REQUEST_SUCCEEDED = 'STORE_USER_AVATAR_UPDATE_REQUEST_SUCCEEDED';

export const STORE_USER_AVATAR_DELETE_REQUEST_INIT = 'STORE_USER_AVATAR_DELETE_REQUEST_INIT';
export const STORE_USER_AVATAR_DELETE_REQUEST_RESET = 'STORE_USER_AVATAR_DELETE_REQUEST_RESET';
export const STORE_USER_AVATAR_DELETE_REQUEST_FAILED = 'STORE_USER_AVATAR_DELETE_REQUEST_FAILED';
export const STORE_USER_AVATAR_DELETE_REQUEST_SUCCEEDED = 'STORE_USER_AVATAR_DELETE_REQUEST_SUCCEEDED';

export const STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_INIT = 'STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_INIT';
export const STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_RESET = 'STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_RESET';
export const STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_FAILED = 'STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_FAILED';
export const STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_SUCCEEDED = 'STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_SUCCEEDED';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Set int data into store
export const storeUserLogoutRequestInit = () => ({
    type: STORE_USER_LOGOUT_REQUEST_INIT
});

// Set failed data into store
export const storeUserLogoutRequestFailed = ({message}) => ({
    type: STORE_USER_LOGOUT_REQUEST_FAILED,
    payload: {message}
});

// Set success data into store
export const storeUserLogoutRequestSucceeded = ({message}) => ({
    type: STORE_USER_LOGOUT_REQUEST_SUCCEEDED,
    payload: {message}
});

// Set reset data into store
export const storeUserLogoutRequestReset = () => ({
    type: STORE_USER_LOGOUT_REQUEST_RESET
});
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Set int data into store
export const storeUserRefreshDataRequestInit = () => ({
    type: STORE_REFRESH_USER_DATA_REQUEST_INIT
});

// Set failed data into store
export const storeUserRefreshDataRequestFailed = () => ({
    type: STORE_REFRESH_USER_DATA_REQUEST_FAILED
});

// Set success data into store
export const storeUserRefreshDataRequestSucceeded = ({user}) => ({
    type: STORE_REFRESH_USER_DATA_REQUEST_SUCCEEDED,
    payload: {user}
});
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Set int data into store
export const storeAttemptUserAuthenticationRequestInit = () => ({
    type: STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_INIT
});

// Set failed data into store
export const storeAttemptUserAuthenticationRequestFailed = ({message}) => ({
    type: STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_FAILED,
    payload: {message}
});

// Set success data into store
export const storeAttemptUserAuthenticationRequestSucceeded = ({message, user}) => ({
    type: STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_SUCCEEDED,
    payload: {message, user}
});

// Set reset data into store
export const storeAttemptUserAuthenticationRequestReset = () => ({
    type: STORE_ATTEMPT_USER_AUTHENTICATION_REQUEST_RESET
});
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Set int data into store
export const storeUserAvatarUpdateRequestInit = () => ({
    type: STORE_USER_AVATAR_UPDATE_REQUEST_INIT
});

// Set failed data into store
export const storeUserAvatarUpdateRequestFailed = ({message}) => ({
    type: STORE_USER_AVATAR_UPDATE_REQUEST_FAILED,
    payload: {message}
});

// Set success data into store
export const storeUserAvatarUpdateRequestSucceeded = ({message, avatar}) => ({
    type: STORE_USER_AVATAR_UPDATE_REQUEST_SUCCEEDED,
    payload: {message, avatar}
});

// Set reset data into store
export const storeUserAvatarUpdateRequestReset = () => ({
    type: STORE_USER_AVATAR_UPDATE_REQUEST_RESET
});
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Set int data into store
export const storeUserAvatarDeleteRequestInit = () => ({
    type: STORE_USER_AVATAR_DELETE_REQUEST_INIT
});

// Set failed data into store
export const storeUserAvatarDeleteRequestFailed = ({message}) => ({
    type: STORE_USER_AVATAR_DELETE_REQUEST_FAILED,
    payload: {message}
});

// Set success data into store
export const storeUserAvatarDeleteRequestSucceeded = ({message}) => ({
    type: STORE_USER_AVATAR_DELETE_REQUEST_SUCCEEDED,
    payload: {message}
});

// Set reset data into store
export const storeUserAvatarDeleteRequestReset = () => ({
    type: STORE_USER_AVATAR_DELETE_REQUEST_RESET
});
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Set int data into store
export const storeUserInfoUpdateRequestInit = () => ({
    type: STORE_USER_INFO_UPDATE_REQUEST_INIT
});

// Set failed data into store
export const storeUserInfoUpdateRequestFailed = ({message}) => ({
    type: STORE_USER_INFO_UPDATE_REQUEST_FAILED,
    payload: {message}
});

// Set success data into store
export const storeUserInfoUpdateRequestSucceeded = ({message, name, email, phone, description}) => ({
    type: STORE_USER_INFO_UPDATE_REQUEST_SUCCEEDED,
    payload: {message, name, email, phone, description}
});

// Set reset data into store
export const storeUserInfoUpdateRequestReset = () => ({
    type: STORE_USER_INFO_UPDATE_REQUEST_RESET
});
