// Manage succeeded request
export const requestSucceeded = (request) => {
    const {failed, loading, succeeded} = request
    return succeeded && !failed && !loading;
};

// Manage failed request
export const requestFailed = (request) => {
    const {failed, loading, succeeded} = request
    return !succeeded && failed && !loading;
};

// Manage loading request
export const requestLoading = (request) => {
    const {failed, loading, succeeded} = request
    return !succeeded && !failed && loading;
};

// Default value for a request
export const requestDefaultValue = () => {
    return {failed: false, loading: false, succeeded: false};
};

// Init value for a request
export const requestInitValue = () => {
    return {failed: false, loading: true, succeeded: false};
};

// Failed value for a request
export const requestFailedValue = (message = "") => {
    return {failed: true, loading: false, succeeded: false, message};
};

// Succeeded value for a request
export const requestSucceededValue = (message = "") => {
    return {failed: false, loading: false, succeeded: true, message};
};