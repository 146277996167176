import React from "react";
import {Button} from "reactstrap";
import * as Icon from "react-feather" ;
import ScrollToTop from "react-scroll-up";

import * as envConstants from "../constants/envConstants";

const Footer = () => {
    return (
        <footer className="footer footer-light footer-static">
            <p className="mb-0 clearfix">
                <span className="float-md-left d-block d-md-inline-block mt-25">
                    COPYRIGHT {envConstants.COMPANY.COPYRIGHT}
                    <a href={envConstants.COMPANY.URL} target="_blank" rel="noopener noreferrer">
                        {envConstants.COMPANY.NAME},
                    </a>
                    All rights reserved
                </span>
            </p>
            <ScrollToTop showUnder={160}>
                <Button className="btn-icon scroll-top btn-theme-brown">
                    <Icon.ArrowUp size={15} />
                </Button>
            </ScrollToTop>
        </footer>
    );
};

export default React.memo(Footer);
