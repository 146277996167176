import React from 'react';

import AuthLayout from "./AuthLayout";
import GuestLayout from "./GuestLayout";

const ContextLayout = React.createContext('layout');

const Layout = ({children}) => {
    return (
        <ContextLayout.Provider value={{guest: GuestLayout, auth: AuthLayout}}>
            {children}
        </ContextLayout.Provider>
    );
};

export { Layout, ContextLayout };