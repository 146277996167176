import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";

import * as generalConstants from "../../constants/generalConstants";

const MenuBody = ({menuItems, deviceWidth, toggleSidebarVisibility, activePath}) => {
    // Local state
    const [activeItem, setActiveItem] = useState(null);
    const [hoveredMenuItem, setHoveredMenuItem] = useState(null);

    // Local effects
    useEffect(() => {
        setActiveItem(activePath);
    }, [activePath]);

    const handleActiveItem = (path) => {
        setActiveItem(path);
    };

    const handleSidebarMouseEnter = (path) => {
        if (path !== hoveredMenuItem) setHoveredMenuItem(path);
        else setHoveredMenuItem(null);
    };

    return (
        menuItems.map((item, key) => {
            // Render group header
            if (item.type === HEADER) {
                return (
                    <li className="navigation-header" key={key}>
                        <span>{item.title}</span>
                    </li>
                )
            }

            // Render simple item
            if (item.type === ITEM) {
                const itemClass = `nav-item ${(hoveredMenuItem === item.path) ? "hover" : ""} ${(activeItem === item.path) ? "active" : ""}`;
                return (
                    <li
                        key={key}
                        className={itemClass}
                        onClick={e => {
                            e.stopPropagation();
                            handleActiveItem(item.path);
                            if (deviceWidth <= 1200) {
                                toggleSidebarVisibility();
                            }
                        }}
                    >
                        <Link
                            to={item.path}
                            className="d-flex justify-content-start"
                            onMouseEnter={() => handleSidebarMouseEnter(item.path)}
                            onMouseLeave={() => handleSidebarMouseEnter(item.path)}
                        >
                            <div className="menu-text">
                                {item.icon}
                                <span className="menu-item menu-title">
                                    {item.title}
                                </span>
                            </div>
                        </Link>
                    </li>
                )
            }

            return null;
        })
    );
};

// External data
const {ITEM, HEADER} = generalConstants.MENU_ITEMS_TYPE;

// Prop types to ensure destroyed props data type
MenuBody.propTypes = {
    menuItems: PropTypes.array.isRequired,
    activePath: PropTypes.string.isRequired,
    deviceWidth: PropTypes.number.isRequired,
    toggleSidebarVisibility: PropTypes.func.isRequired,
};

export default React.memo(MenuBody);
