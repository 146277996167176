import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "reactstrap";
import * as Icon from "react-feather";

import {APP} from "../../constants/envConstants";

const MenuHeader = ({menuShadow, sidebarHover, toggleSidebarVisibility, handleSidebarCollapse, handleSidebarHover}) => {
    return (
        <div className="navbar-header">
            <ul className="nav navbar-nav flex-row">
                <li className="nav-item mr-auto">
                    <NavLink to="/" className="navbar-brand">
                        <img src={require("../../assets/img/logo.png")} alt="..." width={25} />
                        <h2 className="brand-text mb-0 text-uppercase">{APP.FULL_NAME}</h2>
                    </NavLink>
                </li>
                <li className="nav-item nav-toggle">
                    <div className="nav-link modern-nav-toggle">
                        {sidebarHover ? (
                            <Icon.Circle
                                size={25}
                                onClick={() => {
                                    handleSidebarCollapse(false);
                                    handleSidebarHover(false);
                                }}
                                className="toggle-icon icon-x d-none d-xl-block font-medium-4 text-primary"
                            />
                        ) : (
                            <Icon.Disc
                                size={25}
                                data-tour="toggle-icon"
                                onClick={() => {
                                    handleSidebarCollapse(true);
                                    handleSidebarHover(true);
                                }}
                                className="toggle-icon icon-x d-none d-xl-block font-medium-4 text-primary"
                            />
                        )}
                        <Icon.Delete
                            size={25}
                            onClick={toggleSidebarVisibility}
                            className="toggle-icon icon-x d-block d-xl-none font-medium-4 text-primary"
                        />
                    </div>
                </li>
            </ul>
            <div className={`shadow-bottom ${!menuShadow ? "d-none" : ''}`} />
        </div>
    );
}

// Prop types to ensure destroyed props data type
MenuHeader.propTypes = {
    menuShadow: PropTypes.bool.isRequired,
    sidebarHover: PropTypes.bool.isRequired,
    handleSidebarHover: PropTypes.func.isRequired,
    handleSidebarCollapse: PropTypes.func.isRequired,
    toggleSidebarVisibility: PropTypes.func.isRequired,
};

export default React.memo(MenuHeader);
