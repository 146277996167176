import axios from "axios";

import * as generalHelpers from "./generalHelpers";
import * as envConstants from "../constants/envConstants";
import * as urlConstants from "../constants/urlConstants";
import * as localStorageHelpers from "./localStorageHelpers";
import * as errorConstants from "../constants/errorConstants";

const axiosApiInstance = axios.create({timeout: 20000});
const {CONTROLLED_XHR_REQUEST_ERROR, UNCONTROLLED_XHR_REQUEST_ERROR} = errorConstants;

// Axios request interceptor to add automatically token
axiosApiInstance.interceptors.request.use(config => {
    config.headers["content-type"] = 'application/json';
    // Add token to request header if exist into local storage
    const accessToken = localStorageHelpers.getLocaleStorageItem(envConstants.LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    if(accessToken) {
        config.headers["authorization"] = `Bearer ${accessToken}`;
    }
    return config;
}, error => Promise.reject(error));

// Axios response interceptor to automatically refresh token
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async (error) => {
    if(error.response) {
        const originalRequest = error.config;
        if (error.response?.status === 406 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Obtain new access token (old one has expire)
            try {
                const refreshToken = localStorageHelpers.getLocaleStorageItem(envConstants.LOCAL_STORAGE_KEY.REFRESH_TOKEN);
                if(refreshToken) {
                    const config = {headers: {passport: `Bearer ${refreshToken}`}}
                    const response = await axiosApiInstance.post(urlConstants.AUTH.TOKEN, {}, config);
                    if(response.data?.status) {
                        const accessToken = response.data?.data;
                        localStorageHelpers.setLocaleStorageItem(envConstants.LOCAL_STORAGE_KEY.ACCESS_TOKEN, accessToken);
                        return axiosApiInstance(originalRequest);
                    }
                }
            } catch (e) {
                // log
                generalHelpers.log("Xhr refresh token request error", {e});
            }
        }
    }
    return Promise.reject(error);
});

// Get verb xhr request
export const xhrGetRequest = async (url, config = null) => {
    try {
        const response = await axiosApiInstance.get(url, config);
        return manageXhrSuccess(response);
    } catch (e) {
        return manageXhrError(e);
    }
};

// Post verb xhr request
export const xhrPostRequest = async (url, data = {}, config = null) => {
    try {
        const response = await axiosApiInstance.post(url, data, config);
        return manageXhrSuccess(response);
    } catch (e) {
        return manageXhrError(e);
    }
};

// Put verb xhr request
export const xhrPutRequest = async (url, data = {}, config = null) => {
    try {
        const response = await axiosApiInstance.put(url, data, config);
        return manageXhrSuccess(response);
    } catch (e) {
        return manageXhrError(e);
    }
};

// Delete verb xhr request
export const xhrDeleteRequest = async (url, config = null) => {
    try {
        const response = await axiosApiInstance.delete(url, config);
        return manageXhrSuccess(response);
    } catch (e) {
        return manageXhrError(e);
    }
};

// Manage xhr success
const manageXhrSuccess = (response) => {
    return {
        ...response.data,
        message: (
            CONTROLLED_XHR_REQUEST_ERROR[response.data.message] ||
            UNCONTROLLED_XHR_REQUEST_ERROR[response.data.message] ||
            response.data.message
        )
    };
};

// Manage xhr error
const manageXhrError = (error) => {
    // log
    generalHelpers.log("Xhr request error", error);

    // Controlled messages
    if(error.response && error.response.data && error.response.data.message) {
        return {
            ...error.response.data,
            message: (
                CONTROLLED_XHR_REQUEST_ERROR[error.response.data.message] ||
                UNCONTROLLED_XHR_REQUEST_ERROR[error.response.data.message] ||
                error.response.data.message
            )
        }
    }
    // Uncontrolled message
    return {
        data: null,
        status: false,
        message: (
            UNCONTROLLED_XHR_REQUEST_ERROR[error.message] ||
            CONTROLLED_XHR_REQUEST_ERROR[error.message] ||
            error.message
        )
    };
};
