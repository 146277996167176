import React from "react";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Badge, Media, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

import * as pageConstants from "../../constants/pageConstants";

const NavbarNotifications = ({history}) => {
    return (
        <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
            <DropdownToggle tag="a" className="nav-link nav-link-label">
                {pageConstants.NOTIFICATIONS.ICON}
                <Badge pill color="primary" className="badge-up">5</Badge>
            </DropdownToggle>
            <DropdownMenu tag="ul" right className="dropdown-menu-media">
                <li className="dropdown-menu-header">
                    <div className="dropdown-header mt-0">
                        <h3 className="text-white">5 Nouvelle(s)</h3>
                        <span className="notification-title">Notifications</span>
                    </div>
                </li>
                <PerfectScrollbar className="media-list overflow-hidden position-relative" options={{wheelPropagation: false}}>
                    <div className="d-flex justify-content-between">
                        <Media className="d-flex align-items-start">
                            <Media left href="#">
                                <Icon.PlusSquare className="font-medium-5 primary" size={21}/>
                            </Media>
                            <Media body>
                                <Media heading className="primary media-heading" tag="h6">
                                    You have new order!
                                </Media>
                                <p className="notification-text">
                                    Are your going to meet me tonight?
                                </p>
                            </Media>
                            <small>
                                <time
                                    className="media-meta"
                                    dateTime="2015-06-11T18:29:20+08:00"
                                >
                                    9 hours ago
                                </time>
                            </small>
                        </Media>
                    </div>
                </PerfectScrollbar>
                <li className="dropdown-menu-footer">
                    <DropdownItem
                        tag="a"
                        href="#"
                        className="p-1 text-center"
                        onClick={(e) => {
                            e.preventDefault();
                            history.push(pageConstants.NOTIFICATIONS.PATH);
                        }}
                    >
                        <span className="align-middle">Toutes les notifications</span>
                    </DropdownItem>
                </li>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

// Prop types to ensure destroyed props data type
NavbarNotifications.propTypes = {
    history: PropTypes.object.isRequired,
};

export default React.memo(NavbarNotifications);
